.navbar-container {
  z-index: 100;
  background: none;
  width: 100%;
  height: 50px;
  position: absolute;
  @include flexbox;
  @include justify-content(space-between);
  @include align-items(center);
  padding: 0 20px;
}

.navbar-ul {
  @include flexbox;
  @include justify-content(flex-end);
  width: 100%;
  height: 100%;
  li {
    margin-right: $spacing-l;
    line-height: 70px;
    a {
      color: $gray400;
      text-decoration: none;
    }
    .active-link {
      color: $orange;
      border-bottom: 1px solid $orange;
      font-family: $extrabold;
    }
  }
}

.nav-logo {
  img {
    width: 50px;
  }
}

.nav-dropdown {
  .glyphicon {
    margin-right: $spacing-xs;
    color: $orange;
  }
  .caret {
    margin-left: $spacing-xxs;
  }
  .btn-default {
    color: $gray400;
    font-family: $regular;
  }
}

.navigation-dropdown {
  position: relative;
  display: inline-block;
  li {
    list-style-type: none;
    margin-right: 0;
  }
}

.navigation-dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 200px;
  box-shadow: 0px 6px 5px -4px rgba(0, 0, 0, 0.4);
  z-index: 1;
  border-radius: 4px;
  top: 40px;
  left: 50%;
  transform: translate(-50%);
}

.navigation-dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.navigation-dropdown-content a:hover {
  background-color: #ddd;
}

.navigation-dropdown:hover .navigation-dropdown-content {
  display: block;
}

.admin-dropdown {
  box-shadow: -6px 0px 5px -4px rgba(0, 0, 0, 0.22);
  padding: 20px;
  position: fixed;
  width: 240px;
  background: $gray400;
  height: 100%;
  right: 0;
  top: 0;
  overflow-y: scroll;
  li {
    list-style-type: none;
    color: #fff;
    font-family: $regular;
  }
  a {
    color: #fff;
    display: block;
    font-size: 16px;
    padding: 5px 0;
    margin-bottom: 5px;
    text-decoration: none;
  }
}

.close-navigation {
  width: 20px;
  position: absolute;
  top: 15px;
  right: 10px;
  cursor: pointer;
}

.open-navigation {
  width: 20px;
  cursor: pointer;
  padding: 10px;
  box-sizing: content-box;
}
